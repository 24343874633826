let common = require('../admin-common.js');

$(document).on('click', '.clear-data-order-search', function() {
  $('#q_school_code_cont').val('');
  $('#q_order_set_code_cont').val('');
  $('#q_order_set_order_set_name_cont').val('');
  $('#q_order_set_school_year_eq').val('');
  $('#q_confirmed_flg_eq').val(common.allStatus);
});

$(document).on('click', '#download-data', function() {
  let reservation_ids = [];
  let address_code = $('#address_code').val();
  $(':checkbox:checked').each(function(i){
    reservation_ids[i] = $(this).attr('reservation_ids');
  });

  if (!common.AddressCodeRegex(address_code)) {
    common.AddClassErrorAddressCode();
  } else if (reservation_ids.length < 1) {
    common.RemoveClassErrorAddressCode();
    $('.warning-text p').text(common.MSG_EXTRACT_CSV);
    $('#warning-extract-modal').modal('show');
  } else {
    common.RemoveClassErrorAddressCode();
    $.ajax({
      url: '/agency/data_orders/download_temporary',
      type:'POST',
      dataType:'script',
      data: { reservation_ids: reservation_ids, address_code: address_code},
      success: function(data){
       return true;
      }
    });
  }
});

$(document).on('click', '#download-data-invoice', function() {
  let reservation_ids = [];
  let address_code = $('#address_code').val();
  $(':checkbox:checked').each(function(i){
    reservation_ids[i] = $(this).attr('reservation_ids');
  });
  if (!common.AddressCodeRegex(address_code)) {
    common.AddClassErrorAddressCode();
  } else if (reservation_ids.length < 1) {
    common.RemoveClassErrorAddressCode();
    $('.warning-text p').text(common.MSG_EXTRACT_CSV);
    $('#warning-extract-modal').modal('show');
  } else {
    common.RemoveClassErrorAddressCode();
    $('#loading-icon').show();
    $.ajax({
      url: '/agency/data_orders/download_invoice_excel',
      type: 'GET',
      dataType: 'script',
      data: { reservation_ids: reservation_ids, address_code: address_code},
      success: function(data){
        $('#loading-icon').hide();
        if (data) {
          return true
        } else {
          $('.warning-text p').text(common.MSG_INVOICE_ERROR);
          $('#warning-extract-modal').modal('show');
        }
      }
    });
  }
});

$(document).on('click', '#extract-data-invoice-pdf', function() {
  let reservation_ids = [];
  let address_code = $('#address_code').val();
  $(':checkbox:checked').each(function(i){
    reservation_ids[i] = $(this).attr('reservation_ids');
  });
  if (!common.AddressCodeRegex(address_code)) {
    common.AddClassErrorAddressCode();
  } else if (reservation_ids.length < 1) {
    common.RemoveClassErrorAddressCode();
    $('.warning-text p').text(common.MSG_EXTRACT_CSV);
    $('#warning-extract-modal').modal('show');
  } else {
    common.RemoveClassErrorAddressCode();
    $('#loading-icon').show();
    $.ajax({
      url: '/agency/data_orders/download_invoice_pdf',
      type: 'GET',
      dataType: 'script',
      data: { reservation_ids: reservation_ids, address_code: address_code },
      success: function(data){
        $('#loading-icon').hide();
        if (data) {
          return true
        } else {
          $('.warning-text p').text(common.MSG_INVOICE_ERROR);
          $('#warning-extract-modal').modal('show');
        }
      }
    });
  }
});
