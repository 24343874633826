let common = require('../admin-common.js');

$(document).on('click', '.clear-deadlines-search', function() {
  $('#q_school_code_cont').val('');
  $('#q_order_set_code_cont').val('');
  $('#q_order_set_order_set_name_cont').val('');
  $('#q_order_set_school_year_eq').val('');
});

$(document).on('click', '.export-csv-deadlines', function() {
  let params = window.location.search.substr(1);
  let url = '/agency/deadlines/export?' + params;
  window.open(url);
});
