let common = require('../admin-common.js');

$(document).ready(function() {
  $('#order-set-select').select2({ language: common.noResultMessage });
  $('#school-select').select2({ language: common.noResultMessage });

  if (!$('#fixed-store').val()) {
    $('#order-set-select').prop('disabled', true);
  }

  if (!$('#order-set-select').val()) {
    $('#school-select').prop('disabled', true);
  }

  $('#order-set-select').on('change', function() {
    $('.qrcode-result').css('display', 'none');
    $('#school-select').val('').trigger('change');
    let orderSetCode = $(this).val();

    if (orderSetCode) {
      $('#school-select').prop('disabled', false);
      let storeId = $('#store-select').val();

      $.ajax({
        url: '/agency/qr_code/find_school/' + storeId + '/' + orderSetCode,
        type:'GET',
        dataType:'script',
        success: function(data){
          let parsedData = JSON.parse(data);
          parsedData.unshift({id: '', text: '－－'});
          $('#school-select').empty();
          $('#school-select').select2({data: parsedData, language: common.noResultMessage}).trigger('change');
        }
      });
    } else {
      $('#school-select').prop('disabled', true);
    }
  });

  $('#school-select').on('change', function() {
    $('.qrcode-result').css('display', 'none');
  });
});
