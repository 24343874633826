let common = require('../admin-common.js');

$(document).on('click', '.clear-store-order-set-products-search', function() {
  $('#q_order_set_code_cont').val('');
  $('#q_product_code_cont').val('');
  $('#q_order_set_school_year_eq').val('');
});

$(document).on('click', '.export-csv-store-order-set-product', function() {
  let params = window.location.search.substr(1);
  let url = '/agency/store_order_set_products/export?' + params;
  handleDownloadCsvFile(url);
});
