let common = require('./admin-common.js');

$(document).on('change', '#paging', function() {
  let searchUrl = window.location.search;
  searchUrl = searchUrl.substr(1);
  searchUrl = searchUrl.replace(/page=\d*\&?/g, '');
  const paging = 'paging='+ $(this).val();
  if (searchUrl.includes('paging=')) {
    searchUrl = searchUrl.replace(/paging=\d*/g, paging);
  } else if (searchUrl.length === 0) {
    searchUrl = paging;
  } else {
    searchUrl += `&${paging}`;
  }
  const href = window.location.origin + window.location.pathname + `?${searchUrl}`;
  window.location.href = href;
});

$(document).on('click', '.pagination > .disabled', function(event) {
  event.preventDefault();
});

$(document).on('click', '.check-all', function() {
  $(".check-data").prop("checked", !this.checked);
  $(".check-data").click();
});

$(function() {
  $('#payment_date').datepicker( {
      changeMonth: true,
      changeYear: true,
      showButtonPanel: true,
      dateFormat: 'yy/mm',
      monthNamesShort: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
      onClose: function(dateText, inst) {
        function isDonePressed(){
          return ($('#ui-datepicker-div').html().indexOf('ui-datepicker-close ui-state-default ui-priority-primary ui-corner-all ui-state-hover') > -1);
        }
        if (isDonePressed()){
          var month = $("#ui-datepicker-div .ui-datepicker-month :selected").val();
          var year = $("#ui-datepicker-div .ui-datepicker-year :selected").val();
          $(this).datepicker('setDate', new Date(year, month, 1));
        }
      }
  });
});

$(document).on('click', '.btn-export-csv-admin', function() {
  let payment_date = $('#payment_date').val();

  if (payment_date.length < 1) {
    $('.warning-text p').text(common.MSG_A_CSV_0025);
    $('#warning-extract-modal').modal('show');
  } else {
    payment_date = payment_date.replace('/', '');
    url = '/admin/billing_datas/download_csv?payment_date='+ payment_date;
    window.open(url);
  }
});

$(document).on('click', '.btn-export-csv-agency', function() {
  let payment_date = $('#payment_date').val();

  if (payment_date.length < 1) {
    $('.warning-text p').text(common.MSG_A_CSV_0025);
    $('#warning-extract-modal').modal('show');
  } else {
    payment_date = payment_date.replace('/', '');
    url = '/agency/billing_datas/download_csv?payment_date='+ payment_date;
    window.open(url);
  }
});

$(document).on('click', '.btn-change-reservation-status', function (event) {
  $('#reservation-delete-modal').modal('show');
});
