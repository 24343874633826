require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("jquery-ui-dist/jquery-ui.js")

import $ from 'jquery';
global.$ = $;
global.jQuery = $;

import "../stylesheets/agency";
import "./lib/jquery.min.js"
import "./lib/bootstrap.min.js";
import "./agency/qr-code.js";
import "./agency/data-orders.js";
import "./agency/orders.js";
import "select2";
import './agency/store_order_set_products';
import './paginate';
import './agency/deadlines';
import './submit_upload_csv';
import './download_csv';

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)
