let common = require('../admin-common.js');

$(document).on('click', '.btn-edit-info', function() {
  $('#reservation-warning-modal').modal('show');
});

$(document).on('click', '.btn-update-info-reservation', function() {
  $('#reservation-warning-modal').modal('hide');
  $.ajax({
    url: $('.edit_reservation').attr('action'),
    type: 'PATCH',
    dataType: 'script',
    data: $('form.edit_reservation').serialize(),
    success: function() {
      return true;
    }
  });
});

$(document).on('click', '.export-csv-orders', function() {
  let params = window.location.search.substr(1);
  let url = '/agency/orders/export?' + params;
  handleDownloadCsvFile(url);
});

$(document).on('click', '.btn-yes-edit-reservation', function (event) {
  let id = $(this).data('id');
  $('#reservation-delete-modal').modal('hide');
  $.ajax({
    url: '/agency/orders/change_reservation_status',
    type: 'PATCH',
    dataType: 'script',
    data: { id: id },
    success: function(){
      return true;
    }
  });
});

$(document).ready(function() {
  $('.select2-product-code, .select2-school-id').select2({ language: common.noResultMessage });
});
